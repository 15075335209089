<template>
  <!-- select 2 demo -->
  <b-modal
    ref="modal-save-sunucu"
    title="Veri Tabanı Ekle"
    ok-title="Kaydet"
    cancel-title="Kapat"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    centered
    @hidden="hiddenModal"
    @ok="handleOk"
  >
    <validation-observer ref="simpleRules">
      <b-form
        @submit.stop.prevent="saveSunucu">

        <validation-provider #default="{ errors }" rules="required" name="Sunucu Adı" vid="sSunucuAdi">
          <b-form-group label="Sunucu Adı" label-for="sSunucuAdi">
            <b-form-input
              v-model="sunucuData.sSunucuAdi"
              placeholder="Sunucu Adı"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="{ errors }" rules="required" name="Sunucu Adresi" vid="sSunucuAdres">
          <b-form-group label="Sunucu Adresi" label-for="sSunucuAdres">
            <b-form-input
              v-model="sunucuData.sSunucuAdres"
              placeholder="Sunucu Adresi"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="{ errors }" rules="required" name="Kullanıcı Adı" vid="sKullaniciAdi">
          <b-form-group label="Kullanıcı Adı" label-for="sKullaniciAdi">
            <b-form-input
              v-model="sunucuData.sKullaniciAdi"
              placeholder="Kullanıcı Adı"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="{ errors }" rules="required" name="Parola" vid="sParola">
          <b-form-group label="Parola" label-for="sParola">
            <b-form-input
              v-model="sunucuData.sParola"
              placeholder="Parola"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      sunucuData: {
        sSunucuAdres: null,
        sSunucuAdi: null,
        sKullaniciAdi: null,
        sParola: null,
      },
    }
  },
  methods: {
    saveSunucu() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('sunucuYonetim/saveSunucus', this.sunucuData)
            .then(response => {
              if (response.statusCode === 200) {
                this.modalObject.onSuccess(response);
                this.hiddenModal();
                
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
              } else {
                AlertService.error(this, response.message);
              }
            });
        }
      });
    },

    initialModal() {
      this.$refs['modal-save-sunucu'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveSunucu()
    },
    hiddenModal() {
      this.$refs['modal-save-sunucu'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

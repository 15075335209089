import { render, staticRenderFns } from "./SiparisOdemeDetay.vue?vue&type=template&id=ef7b5ddc&scoped=true&"
import script from "./SiparisOdemeDetay.vue?vue&type=script&lang=js&"
export * from "./SiparisOdemeDetay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef7b5ddc",
  null
  
)

export default component.exports
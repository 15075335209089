<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="sunucuSelect"
    :options="selectionSunucuList"
    class="grup-filter-select"
    :reduce="val => val.value"
    @input="changeSunucu"
    placeholder="Sunucu Seç" />
</template>

<script>
export default {
  props: {
    sunucuSelect: {
      type: [Number, null],
      default: null,
    },
    onChange: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  computed: {
    selectionSunucuList() {
      return this.$store.getters['sunucuYonetim/getSunucus'].map(item => {
        item.label = item.sSunucuAdi;
        item.value = item.id;
        return item;
      })
    },
  },
  methods: {
    fetchSunucus() {
      this.$store.dispatch('sunucuYonetim/fetchSunucus')
    },
    changeSunucu(value) {
      this.$emit('update:sunucuSelect', value);

      const findItem = this.$store.getters['sunucuYonetim/getSunucus'].find(item => item.id === value);
      this.onChange(findItem);
    },
  },
  created() {
    this.fetchSunucus();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

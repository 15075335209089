<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-db-info"
      title="Veri Tabanı Ekle"
      ok-title="Kaydet"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <b-form
        ref="form"
        @submit.stop.prevent="saveDbFirmaInfo">
        <b-row>

          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Veri Tabanı Seç')" label-for="selectedSunucu">
              <sunucu-selection :sunucu-select.sync="selectedSunucu" :onChange="onChangeSunucu" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group class="mt-2">
              <b-button size="sm"
                variant="outline-primary"
                @click="sunucuModalObject.showModal = true">
                <feather-icon icon="ServerIcon" class="mr-50" size="20" />
                Yeni Sunucu Ekle
              </b-button>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Veri Tabanı Adı" label-for="sDatabaseName">
              <b-form-input id="sDatabaseName" type="text" v-model="dbInfoData.sDatabaseName" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <save-sunucu v-if="sunucuModalObject.showModal" :modalObject="sunucuModalObject" />
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import SunucuSelection from '../selection/SunucuSelection.vue'
import SaveSunucu from './SaveSunucu.vue'

export default {
  components: {
    SunucuSelection,
    SaveSunucu,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sunucuModalObject: {
        showModal: false,
        onSuccess: this.onSuccessSunucuModal,
      },
      selectedSunucu: null,
      dbInfoData: {
        sDatabaseName: null,
        Musteri: null,
        sDBServerName: null,
        sDBUserName: null,
        sDBPassword: null,
      },
    }
  },
  methods: {
    saveDbFirmaInfo() {
      this.$store.dispatch('dbInfoYonetim/saveDbFirmaInfos', this.dbInfoData)
        .then(response => {
          if (response.statusCode === 200) {
            this.modalObject.onSuccess(response);
            this.hiddenModal();

            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
          } else {
            AlertService.error(this, response.message);
          }
        });
    },

    onChangeSunucu(selectedItem) {
      if (selectedItem) {
        this.dbInfoData.sDBServerName = selectedItem.sSunucuAdi;
        this.dbInfoData.sDBUserName = selectedItem.sKullaniciAdi;
        this.dbInfoData.sDBPassword = selectedItem.sParola;
      }
    },

    onSuccessSunucuModal(response) {
      this.$store.dispatch('sunucuYonetim/fetchSunucus');
      this.selectedSunucu = response.result.id;
      this.dbInfoData.sDBServerName = response.result.sSunucuAdres;
      this.dbInfoData.sDBUserName = response.result.sKullaniciAdi;
      this.dbInfoData.sDBPassword = response.result.sParola;
    },

    initialModal() {
      this.$refs['modal-save-db-info'].show();
      const musteriObject = this.modalObject.musteri;
      if (musteriObject) {
        this.dbInfoData.Musteri = musteriObject;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveDbFirmaInfo()
    },
    hiddenModal() {
      this.$refs['modal-save-db-info'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

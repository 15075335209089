<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="dbKullaniciTurSelect"
    :options="dbKullaniciTurList"
    class="grup-filter-select"
    :reduce="val => val.value"
    @input="changeSunucu"
    :disabled="isDisabled" />
</template>

<script>
export default {
  props: {
    dbKullaniciTurSelect: {
      type: [Number, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dbKullaniciTurList() {
      return this.$store.getters['dbKullaniciTurYonetim/getDbKullaniciTurs'].map(item => {
        item.label = item.sTur;
        item.value = item.id;
        return item;
      })
    },
  },
  methods: {
    fetchDbKullaniciTurs() {
      this.$store.dispatch('dbKullaniciTurYonetim/fetchDbKullaniciTurs')
    },
    changeSunucu(value) {
      this.$emit('update:dbKullaniciTurSelect', value);
    },
  },
  created() {
    this.fetchDbKullaniciTurs();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

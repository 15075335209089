var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-save-sunucu",attrs:{"title":"Veri Tabanı Ekle","ok-title":"Kaydet","cancel-title":"Kapat","cancel-variant":"outline-secondary","no-close-on-backdrop":"","centered":""},on:{"hidden":_vm.hiddenModal,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveSunucu($event)}}},[_c('validation-provider',{attrs:{"rules":"required","name":"Sunucu Adı","vid":"sSunucuAdi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sunucu Adı","label-for":"sSunucuAdi"}},[_c('b-form-input',{attrs:{"placeholder":"Sunucu Adı","state":errors.length > 0 ? false:null},model:{value:(_vm.sunucuData.sSunucuAdi),callback:function ($$v) {_vm.$set(_vm.sunucuData, "sSunucuAdi", $$v)},expression:"sunucuData.sSunucuAdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Sunucu Adresi","vid":"sSunucuAdres"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sunucu Adresi","label-for":"sSunucuAdres"}},[_c('b-form-input',{attrs:{"placeholder":"Sunucu Adresi","state":errors.length > 0 ? false:null},model:{value:(_vm.sunucuData.sSunucuAdres),callback:function ($$v) {_vm.$set(_vm.sunucuData, "sSunucuAdres", $$v)},expression:"sunucuData.sSunucuAdres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Kullanıcı Adı","vid":"sKullaniciAdi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kullanıcı Adı","label-for":"sKullaniciAdi"}},[_c('b-form-input',{attrs:{"placeholder":"Kullanıcı Adı","state":errors.length > 0 ? false:null},model:{value:(_vm.sunucuData.sKullaniciAdi),callback:function ($$v) {_vm.$set(_vm.sunucuData, "sKullaniciAdi", $$v)},expression:"sunucuData.sKullaniciAdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Parola","vid":"sParola"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Parola","label-for":"sParola"}},[_c('b-form-input',{attrs:{"placeholder":"Parola","state":errors.length > 0 ? false:null},model:{value:(_vm.sunucuData.sParola),callback:function ($$v) {_vm.$set(_vm.sunucuData, "sParola", $$v)},expression:"sunucuData.sParola"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card no-body class="p-2">
    <b-row v-if="siparisData">
      <b-col cols="12" md="12" class="mb-3" v-if="siparisData.siparisOdemeIstek.sOdemeNo">
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span><strong>Bildirim!</strong> Sipariş ödemesi online olarak yapıldı.</span>
          </div>
        </b-alert>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Ödeme No')" label-for="sOdemeNo">
            <b-form-input
              id="sOdemeNo"
              v-model="siparisData.siparisOdemeIstek.sOdemeNo"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Ödeme Tutar')" label-for="ldOdemeTutari">
            <b-form-input
              id="ldOdemeTutari"
              v-model="siparisData.siparisOdemeIstek.odemeKayit.ldOdemeTutari"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-col>
      <b-col cols="12" md="12" class="mb-3" v-else>
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span><strong>Bildirim!</strong> Sipariş ödeme dekontunu yüklendi.</span>
          </div>
        </b-alert>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
          @click="downloadFile"
          >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Dekontu indirin') }}</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AlertService from '@/common/alert.service'

export default {
  props: {
    siparisData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      // Validation
      odemeType: null,
    };
  },
  methods: {
    downloadFile() {
      if (this.siparisData.siparisOdemeIstek) {
        const odemeIstekData = this.siparisData.siparisOdemeIstek;
        this.$store.dispatch('siparisYonetim/fetchOdemeDekontFile', odemeIstekData.sGuid)
          .then(response => {
            if (response) {
              const fileURL = window.URL.createObjectURL(new Blob([response], { type: odemeIstekData.sDekontDosyaType }));
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'dekont');
              document.body.appendChild(fileLink);

              fileLink.click();
            } else {
              AlertService.error(this, 'Dosya Bulunamadi')
            }
          })
          .catch(error => {
            AlertService.error(this, error)
          })
      }
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <b-card>
    <b-alert variant="danger" show>
      <div class="alert-body">
        {{ $t('Sipariş Durumu') }}
        <b-badge :variant="siparis.siparisDurumObject.variant">{{ siparis.siparisDurumObject.label }}</b-badge>
        {{ siparis.siparisDurumObject.desc }}
      </div>
    </b-alert>
    <b-card border-variant="primary">
      <b-row class="custom-collapse">
        <b-col>
          <div class="d-flex" @click="onChangeStep('0')">
            <feather-icon icon="CreditCardIcon" size="19" class="text-primary" />
            <h6 class="mb-0 ml-50 text-primary">
              {{ $t('Sipariş Ödeme') }}
            </h6>
          </div>
        </b-col>
      </b-row>
      <div v-show="stepOptions[0] && siparis.siparisDurumu >= 0" class="mt-2">
        <b-row>
          <b-col cols="12" v-if="siparisData.siparisOdemeIstek">
            <siparis-odeme-detay :siparisData="siparisData" />
          </b-col>
          <b-col cols="12">
            <b-form ref="form">
              <b-row>
                <b-col cols="12" md="12">
                  <b-form-group :label="$t('Ödeme Türü Seçiniz')">
                    <v-select
                      v-model="siparisOdeme.odemeSekili"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siparisOdemeSekilOptions"
                      class="w-100"
                      :reduce="val => val.value"
                      :placeholder="$t('Ödeme Türü Seçiniz')" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12" md="12">
                  <b-button 
                    :disabled="siparis.siparisDurumu != 0 || $store.getters.getLoader"
                    variant="warning" 
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" 
                    @click.prevent="onSiparisOdeme">
                    <b-spinner
                      v-if="$store.getters.getLoader"
                      class="mr-1"
                      small
                      variant="light"
                      />
                    <feather-icon
                      v-else
                      icon="SaveIcon"
                      class="mr-50"
                      size="16" />
                    {{ $t('Kaydet') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card border-variant="primary">
      <b-row class="custom-collapse">
        <b-col>
          <div class="d-flex" @click="onChangeStep('1')">
            <feather-icon icon="CheckIcon" size="19" class="text-primary" :class="{'text-danger': siparis.siparisDurumu < 1}" />
            <h6 class="mb-0 ml-50 text-primary" :class="{'text-danger': siparis.siparisDurumu < 1}">
              {{ $t('Sipariş Onaylama') }}
            </h6>
          </div>
        </b-col>
      </b-row>
      <div v-show="stepOptions[1] && siparis.siparisDurumu >= 1" class="mt-2">
        <b-row>
          <b-col>
            <b-form ref="form">
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-alert variant="primary" v-show="siparisCreateDb" show>
                    <div class="alert-body">
                      <span><strong>{{ $t('Önemli') }}!</strong>
                      {{ $t('Bu sipariş ürünlerinde veritabanı oluşturulması gerekiyor') }}. 
                      {{ $t('Veri tabanı bilgilerini seçiniz') }}.</span>
                    </div>
                  </b-alert>
                  <b-alert variant="danger" v-show="!siparisCreateDb" show>
                    <div class="alert-body">
                      <span><strong>{{ $t('Önemli') }}!</strong> 
                      {{ $t('Bu siparişi onaylayınız ve gerekli lisans ve kayıt işlemlerini yapınız') }}.</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              
              <b-row v-if="siparisCreateDb">
                <!-- Field: New DbFirmaInfo -->
                <b-col cols="12" md="6" lg="6">
                  <b-form-group>
                    <db-firma-info-select
                      :dbinfo-select.sync="siparisOnay.dbFirmaInfoId"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: New DbFirmaInfo -->
                <b-col cols="12" md="6" lg="4">
                  <b-form-group>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="modalObject.showModal = true"
                    >
                      <feather-icon icon="DatabaseIcon" class="mr-50" size="20" />
                      {{ $t('Yeni Veri Tabanı Ekle') }}
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Field: dbKullaniciTuruId -->
              <b-row class="mt-1" v-if="siparisCreateDb">
                <b-col cols="12" md="6" lg="6">
                  <b-form-group>
                    <db-kullanici-tur-select
                      :dbKullaniciTurSelect.sync="siparisOnay.dbKullaniciTuruId"
                      :isDisabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="12" md="12">
                  <b-button 
                    :disabled="siparis.siparisDurumu != 1 || $store.getters.getLoader"
                    variant="warning" 
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" 
                    @click.prevent="onSiparisOnayla">
                    <b-spinner
                      v-if="$store.getters.getLoader"
                      class="mr-1"
                      small
                      variant="light"
                    />
                    <feather-icon
                      v-else
                      icon="SaveIcon"
                      class="mr-50"
                      size="16" />
                    {{ $t('Kaydet') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </div>
      <save-db-firma-info
        v-if="modalObject.showModal"
        :modalObject="modalObject" />
    </b-card>

    <b-card border-variant="primary">
      <b-row class="custom-collapse">
        <b-col>
          <div class="d-flex" @click="onChangeStep('2')">
            <feather-icon icon="DownloadIcon" size="19" class="text-primary" :class="{'text-danger': siparis.siparisDurumu < 2}" />
            <h6 class="mb-0 ml-50 text-primary" :class="{'text-danger': siparis.siparisDurumu < 2}">
              {{ $t('Sipariş Kurulum') }}
            </h6>
          </div>
        </b-col>
      </b-row>
      <div v-show="stepOptions[2] && siparis.siparisDurumu >= 2" class="mt-2">
        <b-row>
          <b-col>
            <b-form ref="form">
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-alert variant="primary" v-show="siparis.siparisDurumu === 2" show>
                    <div class="alert-body">
                      <span><strong>{{ $t('Önemli') }}!</strong>
                      {{ $t('Sipariş kurulumu ile ilgili açıklamayı giriniz') }}.</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12">
                  <b-form-group>
                    <b-form-textarea
                      v-model="siparisGerekliKurulum.sAciklama"
                      id="sAciklama"
                      :placeholder="$t('Açıklama')"
                      rows="3" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12" md="12">
                  <b-button 
                    :disabled="siparis.siparisDurumu != 2 || $store.getters.getLoader"
                    variant="warning" 
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" @click.prevent="onSiparisGereksinim">
                    <b-spinner
                      v-if="$store.getters.getLoader"
                      class="mr-1"
                      small
                      variant="light"
                      />
                    <feather-icon
                      v-else
                      icon="SaveIcon"
                      class="mr-50"
                      size="16" />
                    {{ $t('Kaydet') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card border-variant="primary">
      <b-row class="custom-collapse">
        <b-col>
          <div class="d-flex" @click="onChangeStep('3')">
            <feather-icon icon="InboxIcon" size="19" class="text-primary" :class="{'text-danger': siparis.siparisDurumu < 3}" />
            <h6 class="mb-0 ml-50 text-primary" :class="{'text-danger': siparis.siparisDurumu < 3}">
              {{ $t('Sipariş Kapatma') }}
            </h6>
          </div>
        </b-col>
      </b-row>
      <div v-show="stepOptions[3] && siparis.siparisDurumu >= 3" class="mt-2">
        <b-row>
          <b-col>
            <b-form ref="form">
              <b-row class="mt-2">
                <b-col cols="12" md="12">
                  <b-button 
                    :disabled="siparis.siparisDurumu != 3"
                    variant="warning" 
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" @click.prevent="onSiparisKapat">
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                      size="16" />
                    {{ $t('Siparişi Kapat') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import StaticOptions from '@/common/options/StaticOptions';
import AlertService from '@/common/alert.service';

import DbFirmaInfoSelect from '../../selection/DbFirmaInfoSelection.vue'
import SaveDbFirmaInfo from '../../database-yonetim/SaveDbInfo.vue'
import DbKullaniciTurSelect from '../../../general-selection/DbKullaniciTurSelection.vue'
import SiparisOdemeDetay from './SiparisOdemeDetay.vue';

export default {
  components: {
    DbFirmaInfoSelect,
    DbKullaniciTurSelect,
    SaveDbFirmaInfo,
    SiparisOdemeDetay,
  },
  props: {
    siparisData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      stepOptions: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      siparisOdemeSekilOptions: StaticOptions.siparisOdemeSekilOptions,
      siparis: this.siparisData.siparis,
      siparisCreateDb: this.siparisData.isCreatedbKullanici,

      // Odeme
      siparisOdeme: {
        odemeSekili: this.siparisData.siparis.odemeSekili,
      },

      // Onaylama
      modalObject: {
        showModal: false,
        musteri: {
          pId: this.siparisData.siparis.cariId,
        },
        onSuccess: this.onSuccessDbFirmaInfo,
      },
      siparisOnay: {
        dbFirmaInfoId: null,
        dbKullaniciTuruId: 1,
      },

      // Siparis Gereksinim
      siparisGerekliKurulum: {
        sAciklama: null,
      },
    };
  },
  methods: {
    onChangeStep(step) {
      if (this.siparis.siparisDurumu < step) {
        return;
      }
      this.siparis.siparisDurumObject = StaticOptions.siparisDurumlari.find(x => x.value === this.siparis.siparisDurumu)
      for (const key in this.stepOptions) {
        if (Object.hasOwnProperty.call(this.stepOptions, key)) {
          if (key === step) this.stepOptions[key] = !this.stepOptions[key];
          else this.stepOptions[key] = false;
        }
      }
    },

    // Odeme
    onSiparisOdeme() {
      // Control
      if (!this.siparisOdeme.odemeSekili) {
        AlertService.error(this, 'Ödeme Şeklini Seçiniz');
        return;
      }

      const queryParams = {
        siparisDurumu: 1,
        siparis: this.siparis,
        siparisOdeme: this.siparisOdeme,
      };

      this.$store.dispatch('siparisYonetim/siparisOdeme', queryParams)
        .then(response => {
          if (response.statusCode === 200) {
            this.siparis.siparisDurumu = 1;
            AlertService.success(this, response.message)
          } else {
            AlertService.error(this, response.message)
          }
        })
    },

    // Onaylama
    onSiparisOnayla() {
      // Control
      if (this.siparisCreateDb && !this.siparisOnay.dbFirmaInfoId) {
        AlertService.error(this, 'Veri tabanı Seçiniz');
        return;
      }

      const queryParams = {
        siparisDurumu: 2,
        siparis: this.siparis,
        siparisOnay: this.siparisOnay,
      };

      this.$store.dispatch('siparisYonetim/siparisOnayla', queryParams)
        .then(response => {
          if (response.statusCode === 200) {
            this.siparis.siparisDurumu = 2;
            this.onChangeStep(2);
            AlertService.success(this, response.message)
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    onSuccessDbFirmaInfo(response) {
      this.$store.dispatch('dbInfoYonetim/fetchDbFirmaInfos');
      this.siparisOnay.dbFirmaInfoId = response.result.id;
    },

    // Gerekli Gereksinim
    onSiparisGereksinim() {
      // Control
      if (!this.siparisGerekliKurulum.sAciklama) {
        AlertService.error(this, 'Açıklama Giriniz.');
        return;
      }

      const queryParams = {
        siparisDurumu: 3,
        siparis: this.siparis,
        siparisGerekliKurulum: this.siparisGerekliKurulum,
      };

      this.$store.dispatch('siparisYonetim/siparisGerekliKurulum', queryParams)
        .then(response => {
          if (response.statusCode === 200) {
            this.siparis.siparisDurumu = 3;
            this.onChangeStep(3);
            AlertService.success(this, response.message)
          } else {
            AlertService.error(this, response.message)
          }
        })
    },

    // Gerekli Kapat
    onSiparisKapat() {
      const queryParams = {
        siparisDurumu: 4,
        siparis: this.siparis,
      };

      this.$store.dispatch('siparisYonetim/siparisKapat', queryParams)
        .then(response => {
          if (response.statusCode === 200) {
            this.siparis.siparisDurumu = 4;
            this.onChangeStep(4);
            AlertService.success(this, response.message)
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
  mounted() {
    this.stepOptions[this.siparis.siparisDurumu] = true;
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.custom-collapse:hover {
  cursor:pointer;
}
</style>
